/* enables embed by curator.io in the Latest Updates component on the homepage */
export default () => ({
  init() {
    (function () {
      var i,
        e,
        d = document,
        s = "script";
      i = d.createElement("script");
      i.async = 1;
      i.charset = "UTF-8";
      i.src =
        "https://cdn.curator.io/published/bf86e0f1-9dce-4382-bb51-cf7e6d91054b.js";
      e = d.getElementsByTagName(s)[0];
      e.parentNode.insertBefore(i, e);
    })();
  },
});
