import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";

Alpine.plugin(collapse);

export function autoLoadAlpineComponents() {
  const context = require.context("../AlpineComponents", true, /.*.js/);

  context.keys().forEach((key) => {
    const name = key.substr(2, key.length - 5);
    if (name !== "index") {
      // register
      Alpine.data(name, context(key).default);
    }
  });

  Alpine.start();
}
